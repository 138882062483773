var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('general.buyer'),"no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('b-tabs-header',{attrs:{"tabs":_vm.titles,"activeTab":_vm.activeTab},on:{"clickTab":_vm.handleClickTab,"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event},"close":function($event){_vm.modalActive = false}}})]},proxy:true},{key:"default",fn:function(){return [(_vm.activeTab === 0)?_c('div',{attrs:{"title":_vm.$t('general.buyer')}},[_c('validation-observer',{ref:"validation"},[_c('div',{staticClass:"row my-2 secondary-text"},[_c('div',{staticClass:"col mx-1 buyerNotActive",class:{ 'buyerActive': _vm.activeBuyer === _vm.Buyer.TaxPayer},on:{"click":function($event){return _vm.changeActiveState(_vm.Buyer.TaxPayer)}}},[_vm._v(_vm._s(_vm.$t('keys.buyers.tax_payer')))]),_c('div',{staticClass:"col buyerNotActive",class:{ 'buyerActive': _vm.activeBuyer === _vm.Buyer.NotTaxPayer},on:{"click":function($event){return _vm.changeActiveState(_vm.Buyer.NotTaxPayer)}}},[_vm._v(_vm._s(_vm.$t('keys.buyers.not_tax_payer')))]),_c('div',{staticClass:"col mx-1 buyerNotActive",class:{ 'buyerActive': _vm.activeBuyer === _vm.Buyer.Foreign},on:{"click":function($event){return _vm.changeActiveState(_vm.Buyer.Foreign)}}},[_vm._v(_vm._s(_vm.$t('keys.buyers.foreign')))])]),_c('b-form-group',{attrs:{"label":_vm.$t('general.tax_number')}},[_c('b-row',{staticClass:"row p-0 m-0 w-100"},[_c('div',{staticClass:"col-2 p-0"},[_c('b-form-input',{attrs:{"rules":"required|length:2","disabled":_vm.activeBuyer !== _vm.Buyer.Foreign},model:{value:(_vm.prefix),callback:function ($$v) {_vm.prefix=$$v},expression:"prefix"}})],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"name":_vm.$t('general.tax_number'),"rules":_vm.TAXValidationRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"taxNumber",attrs:{"autofocus":""},on:{"input":_vm.checkTAXMatch},model:{value:(_vm.addObject.tax_number),callback:function ($$v) {_vm.$set(_vm.addObject, "tax_number", $$v)},expression:"addObject.tax_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2501140673)})],1),_c('div',{staticClass:"col-2 p-0 w-100"},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.buyerTaxNumberChanged()}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)])],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.name),callback:function ($$v) {_vm.$set(_vm.addObject, "name", $$v)},expression:"addObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1597630706)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.address')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.address),callback:function ($$v) {_vm.$set(_vm.addObject, "address", $$v)},expression:"addObject.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3709006403)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.activeBuyer !== _vm.Buyer.Foreign},model:{value:(_vm.addObject.country),callback:function ($$v) {_vm.$set(_vm.addObject, "country", $$v)},expression:"addObject.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2361939258)})],1)],1)],1):_vm._e(),(_vm.activeTab === 1)?_c('div',{attrs:{"title":_vm.$t('keys.advanced')}},[_c('validation-observer',{ref:"validationAdvanced"},[_c('b-form-group',{attrs:{"label":_vm.$t('keys.predefined_discount')}},[_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.predefinedDiscounts,"reduce":function (item) { return item.id; }},model:{value:(_vm.addObject.predefined_discount_id),callback:function ($$v) {_vm.$set(_vm.addObject, "predefined_discount_id", $$v)},expression:"addObject.predefined_discount_id"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.buyers.days_until_deadline')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.buyers.days_until_deadline'),"rules":"is_number|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.payment_deadline),callback:function ($$v) {_vm.$set(_vm.addObject, "payment_deadline", _vm._n($$v))},expression:"addObject.payment_deadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3796111210)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.buyers.additional_info')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.buyers.additional_info')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.additional_info),callback:function ($$v) {_vm.$set(_vm.addObject, "additional_info", $$v)},expression:"addObject.additional_info"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,769376893)})],1)],1)],1):_vm._e(),(_vm.activeTab === 2)?_c('div',{attrs:{"title":_vm.$t('form_data.contact_person')}},[_c('validation-observer',{ref:"validationContact"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.person_name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.person_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"name",attrs:{"autofocus":""},model:{value:(_vm.addObject.contact.name),callback:function ($$v) {_vm.$set(_vm.addObject.contact, "name", $$v)},expression:"addObject.contact.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1597060789)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.contact.email),callback:function ($$v) {_vm.$set(_vm.addObject.contact, "email", $$v)},expression:"addObject.contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4058808439)})],1),_c('b-form-group',{staticStyle:{"position":"relative"},attrs:{"label":_vm.$t('form_data.phone')}},[_c('span',{staticStyle:{"position":"absolute","right":"10px","margin-top":"10px"}},[_c('fa',{staticClass:"ml-1",attrs:{"id":"supported-phone-formats-tooltip","icon":"question-circle","color":"#72a5d8"}}),_c('b-tooltip',{attrs:{"target":"supported-phone-formats-tooltip","placement":"topright","triggers":"hover focus","custom-class":"expanded-tooltip"}},[_vm._v(" "+_vm._s(_vm.$t('general.supported_formats'))+":"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$supported_phone_formats)}})])],1),_c('validation-provider',{attrs:{"name":_vm.$t('form_data.phone'),"rules":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.contact.phone),callback:function ($$v) {_vm.$set(_vm.addObject.contact, "phone", $$v)},expression:"addObject.contact.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,32158055)})],1)],1)],1):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }