<template>
    <b-modal size="lg" :title="$t('keys.payment_types.payment_type')" v-model="modalActive" no-close-on-backdrop>
        <template #modal-header>
            <b-tabs-header :tabs="titles" @clickTab="handleClickTab" :activeTab.sync="activeTab" @close="modalActive = false" />
        </template>
        <template #default>
            <div v-if="activeTab === 0" @click="focusName" :title="$t('keys.payment_types.payment_type_config')">
                <validation-observer ref="validation">
                    <b-form-group>
                        <b-form-checkbox switch v-model="addObject.active">{{$t('general.active')}}</b-form-checkbox>
                    </b-form-group>
                    <b-form-group :label="$t('form_data.name')">
                        <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                            <b-form-input ref="name" autofocus v-model="addObject.name"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :disabled="paymentTypeAllowed">
                        <b-form-checkbox switch v-model="addObject.tax_certification" @change="taxCertificationChanged">{{$t('keys.payment_types.tax_certification')}}</b-form-checkbox>
                    </b-form-group>
                    <b-form-group :label="$t('keys.payment_types.payment_type')">
                        <validation-provider #default="{ errors }" :name="$t('keys.payment_types.payment_type')" rules="required">
                            <v-select :options="payment_types" v-model="addObject.payment_type" :reduce="(item) => item.value" @input="paymentTypeAllowedChange"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <div v-if="!addObject.tax_certification">
                        <b-form-group class="mt-2" :label="$t('keys.payment_types.custom_numbering')">
                            <validation-provider :disabled="addObject.tax_certification" #default="{ errors }" :name="$t('keys.payment_types.custom_numbering')" rules="required|min:1|alpha_num">
                                <b-form-input v-model="addObject.custom_numbering_key"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>


                    <b-form-group class="mt-2" :label="$t('keys.payment_types.fixed_price')">
                        <b-form-checkbox switch @change="emptyField" v-model="addObject.fixed_price_enabled"/>
                    </b-form-group>

                    <b-form-group v-if="addObject.fixed_price_enabled">
                        <validation-provider ref="fixed_price" :disabled="!addObject.fixed_price_enabled" #default="{ errors }" :name="$t('keys.payment_types.fixed_price')" :rules="`required: ${addObject.fixed_price_enabled}|min_value:0|decimalCustom:2|mustBePositive:true`">
                            <b-input-group>
                                <b-form-input type="number" :disabled="!addObject.fixed_price_enabled" v-model.number="addObject.fixed_price" />
                            </b-input-group>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <validation-provider #default="{ errors }" :name="$t('keys.color')">
                            <colorSelector  v-model="addObject.color" :product-name="addObject.name"></colorSelector>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>
            </div>
            <div v-if="activeTab === 1" :title="$t('keys.advanced')">
                <validation-observer ref="validationAdvanced">

                    <div class="pb-1" >
                        <validation-provider #default="{ errors }" :name="$t('keys.payment_types.print_qr_code_native')" rules="required">
                            <b-form-checkbox switch v-model="addObject.open_cash_drawer">
                                {{$t('general.open_cash_drawer')}}
                            </b-form-checkbox>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>

                        <div class="mt-2" v-if="addObject.payment_type === 0">
                            <b-form-checkbox switch v-model="addObject.pos_integration">{{$t('keys.payment_types.pos_integration')}}</b-form-checkbox>
                        </div>
                    </div>
                </validation-observer>
            </div>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {
        BButton,
        BFormGroup,
        BFormInput,
        BModal,
        BFormCheckbox,
        BInputGroup
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, minValue, decimalCustom, min, alphaNum } from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import colorSelector from '@/views/ColorSelector/ColorSelector.vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'

    export default {
        components: {
            BTabsHeader,
            colorSelector,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckbox,
            BInputGroup,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        data() {
            return {
                titles: [
                    { title: this.$t('keys.payment_types.payment_type_config') },
                    { title: this.$t('keys.advanced') }
                ],
                activeTab: 0,
                paymentTypeAllowed: false,
                modalActive: false,
                addObject: {
                    name: '',
                    active: true,
                    payment_type: 0,
                    tax_certification:false,
                    additional_options: {},
                    fixed_price: '',
                    fixed_price_enabled: false,
                    open_cash_drawer: false,
                    pos_integration: false
                },
                required,
                minValue,
                decimalCustom,
                min,
                alphaNum,
                editing: false
            }
        },
        computed: {
            payment_types() {
                const payment_types = [{label:'Navadno', value:0}, {label:'Lastna Poraba', value:3}, {label:'Dobavnica', value:2}]

                if (this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                    payment_types.push(
                        {label:'Prenos na sobo', value:1}
                    )
                }

                return payment_types
            }
        },
        methods: {
            handleClickTab(index) {
                this.activeTab = index
            },
            async focusName() {
                await this.$nextTick()
                this.$refs.name.focus()
            },
            taxCertificationChanged() {
                if (this.addObject.tax_certification) {
                    this.addObject.use_custom_numbering = false
                    this.addObject.custom_numbering_key = ''
                }
            },
            paymentTypeAllowedChange() {
                if (this.addObject.payment_type !== 0) {
                    this.addObject.pos_integration = false
                }


                if (!this.addObject) {
                    this.paymentTypeAllowed = false
                    return
                }

                if (this.addObject.payment_type === 0) {
                    this.paymentTypeAllowed = false
                    return
                }


                if (this.addObject.payment_type === 3) {
                    this.addObject.tax_certification = true
                    this.addObject.use_custom_numbering = false
                    this.addObject.custom_numbering_key = ''
                    this.paymentTypeAllowed = true
                    return
                }

                if (this.addObject.payment_type === 1 || this.addObject.payment_type === 2) {
                    this.addObject.tax_certification = false
                    this.paymentTypeAllowed = true
                }

            },
            emptyField() {
                if (!this.addObject.fixed_price_enabled) {
                    this.addObject.fixed_price = ''
                    this.$refs.fixed_price.reset()
                }
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    this.addObject.color = this.addObject.color ?? '#545454'
                    this.editing = true
                    this.formatPrice()
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        color: '#545454',
                        active: true,
                        payment_type: 0,
                        tax_certification:false,
                        additional_options: {},
                        fixed_price: '',
                        fixed_price_enabled: false,
                        open_cash_drawer: false
                    }
                }
                this.paymentTypeAllowedChange()
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    this.formatPriceToInt()
                    if (this.addObject.payment_type !== 0) {
                        if (this.addObject.payment_type === 3) {
                            this.addObject.tax_certification = true
                        } else if (this.addObject.payment_type === 1 || this.addObject.payment_type === 2) {
                            this.addObject.tax_certification = false
                        }
                    }

                    this.addObject.use_custom_numbering = !this.addObject.tax_certification

                    if (this.addObject.tax_certification) {
                        this.addObject.custom_numbering_key = ''
                    }

                    const response = await this.$http.post('/api/client/v1/payment_types/', this.addObject)
                    this.modalActive = false

                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        color: '#545454',
                        active: true,
                        payment_type: 0,
                        tax_certification:false,
                        additional_options: {},
                        fixed_price: 0,
                        fixed_price_enabled: false,
                        open_cash_drawer: false,
                        pos_integration: false

                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    this.formatPriceToInt()
                    if (this.addObject.payment_type !== 0) {
                        if (this.addObject.payment_type === 3) {
                            this.addObject.tax_certification = true
                        } else if (this.addObject.payment_type === 1 || this.addObject.payment_type === 2) {
                            this.addObject.tax_certification = false
                        }

                        this.addObject.pos_integration = false
                    }
                    this.addObject.use_custom_numbering = !this.addObject.tax_certification

                    await this.$http.put(`/api/client/v1/payment_types/${this.addObject.id}`, this.addObject)
                    this.modalActive = false

                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        color: '#545454',
                        active: true,
                        payment_type: 0,
                        tax_certification:false,
                        additional_options: {},
                        fixed_price: 0,
                        fixed_price_enabled: false,
                        open_cash_drawer: false,
                        pos_integration: false

                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            formatPrice() {
                if (this.addObject.fixed_price > 0) {
                    this.addObject.fixed_price = this.addObject.fixed_price / 1000000
                }
            },
            formatPriceToInt() {
                this.addObject.fixed_price =  Number(Number(Number(this.addObject.fixed_price) * 1000000).toFixed(0))
            }

        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
