var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('general.employees'),"no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('b-tabs-header',{attrs:{"tabs":_vm.titles,"activeTab":_vm.activeTab},on:{"clickTab":_vm.handleClickTab,"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event},"close":function($event){_vm.modalActive = false}}})]},proxy:true},{key:"default",fn:function(){return [(_vm.activeTab === 0)?_c('div',[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.person_name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.person_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"name",attrs:{"autofocus":""},on:{"click":_vm.focusName},model:{value:(_vm.addObject.name),callback:function ($$v) {_vm.$set(_vm.addObject, "name", $$v)},expression:"addObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2828340405)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.employees.print_name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.employees.print_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.print_name),callback:function ($$v) {_vm.$set(_vm.addObject, "print_name", $$v)},expression:"addObject.print_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3194860796)})],1),(!_vm.addObject.is_employee_foreign)?_c('b-form-group',{attrs:{"label":_vm.$t('general.tax_number')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.tax_number'),"rules":"required|alpha_num|taxNew"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.addObject.tax_number),callback:function ($$v) {_vm.$set(_vm.addObject, "tax_number", $$v)},expression:"addObject.tax_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,76165931)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$t('keys.pin')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.pin'),"rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addObject.access_pin),callback:function ($$v) {_vm.$set(_vm.addObject, "access_pin", $$v)},expression:"addObject.access_pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1169101081)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.foreign_employee')}},[_c('b-form-checkbox',{ref:"input",staticClass:"w-100 active-editor",attrs:{"switch":""},on:{"change":function($event){_vm.addObject.tax_number = null}},model:{value:(_vm.addObject.is_employee_foreign),callback:function ($$v) {_vm.$set(_vm.addObject, "is_employee_foreign", $$v)},expression:"addObject.is_employee_foreign"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('keys.permissions.permissions')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.permissions.permissions'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.employeePermissionGroups,"reduce":function (item) { return item.id; }},model:{value:(_vm.addObject.employee_permission_group_id),callback:function ($$v) {_vm.$set(_vm.addObject, "employee_permission_group_id", $$v)},expression:"addObject.employee_permission_group_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,205924237)})],1)],1)],1):_vm._e(),(_vm.activeTab === 1)?_c('div',[_c('validation-observer',{ref:"validationBusinessUnits"},[_c('b-form-group',{attrs:{"label":_vm.$t('keys.can_login_to_all_business_units')}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.addObject.can_login_to_all_business_units),callback:function ($$v) {_vm.$set(_vm.addObject, "can_login_to_all_business_units", $$v)},expression:"addObject.can_login_to_all_business_units"}})],1),(!_vm.addObject.can_login_to_all_business_units)?_c('b-form-group',{attrs:{"label":_vm.$t('keys.allowed_business_units_login')}},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.allowed_business_units_login'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","appendToBody":"","label":"name","options":_vm.businessUnits,"reduce":function (item) { return item.id; }},model:{value:(_vm.addObject.allowed_business_units),callback:function ($$v) {_vm.$set(_vm.addObject, "allowed_business_units", $$v)},expression:"addObject.allowed_business_units"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1220943826)})],1):_vm._e()],1)],1):_vm._e(),(_vm.activeTab === 2)?_c('div',[_c('validation-observer',{ref:"validationContact"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.address')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"address",attrs:{"autofocus":""},model:{value:(_vm.contact.address),callback:function ($$v) {_vm.$set(_vm.contact, "address", $$v)},expression:"contact.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1686948687)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.country')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contact.country),callback:function ($$v) {_vm.$set(_vm.contact, "country", $$v)},expression:"contact.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1497431017)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4064006221)})],1),_c('b-form-group',{staticStyle:{"position":"relative"},attrs:{"label":_vm.$t('form_data.phone')}},[_c('span',{staticStyle:{"position":"absolute","right":"10px","margin-top":"10px"}},[_c('fa',{staticClass:"ml-1",attrs:{"id":"supported-phone-formats-tooltip","icon":"question-circle","color":"#72a5d8"}}),_c('b-tooltip',{attrs:{"target":"supported-phone-formats-tooltip","placement":"topright","triggers":"hover focus","custom-class":"expanded-tooltip"}},[_vm._v(" "+_vm._s(_vm.$t('general.supported_formats'))+":"),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$supported_phone_formats)}})])],1),_c('validation-provider',{attrs:{"name":_vm.$t('form_data.phone'),"rules":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,345733341)})],1)],1)],1):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }